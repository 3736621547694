import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { consultantInterviewApi } from '../../api/consultantInterview';
import {
  TCertificate,
  TConsultantInterview,
  TEmployeeInterviewList,
  TFeedbackConsultant,
  TParams,
  TScheduleParams,
} from '../../types/consultantInterview/consultantInterview';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../types/common';
import { TInterviewerManagement } from '../../types/interviewerManagement/interviewerManagement';
import { setShowToast } from '../toast/toastSlice';
import { ETypeToast } from '../../app/enum';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { getLabelError } from '../../helpers/funcs';
import { getInterviewDetail } from '../interviewer/interviewerSlice';

export type ConsultantInterviewState = {
  loading: boolean;
  listEmployees: TEmployeeInterviewList[];
  totalCancel: number;
  totalCompleted: number;
  totalAvailable: number;
  totalSendToEmployee: number;
  totalScheduled: number;
  totalNotFb: number;
  listEmployeesFilter: TEmployeeInterviewList[];
  listSchedule: Array<any>;
  listFeedbacks: Array<any>;
  listReservationStatus: Array<any>;
  totalAllEvents: number;
  totalEventsByMonth: number;
  infoConsultant: TInterviewerManagement;
  listCertificate: TCertificate[];
};

const initialState: ConsultantInterviewState = {
  loading: false,
  listEmployees: [],
  totalCancel: 0,
  totalCompleted: 0,
  totalAvailable: 0,
  totalScheduled: 0,
  totalNotFb: 0,
  listEmployeesFilter: [],
  listSchedule: [],
  listFeedbacks: [],
  listReservationStatus: [],
  totalAllEvents: 0,
  totalEventsByMonth: 0,
  infoConsultant: {},
  listCertificate: [],
  totalSendToEmployee: 0,
};

export const getListFeedback = createAsyncThunk(
  'consultantInterview/getListFeedback',
  async (params: TFeedbackConsultant, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await consultantInterviewApi.getListFeedback(params);
    thunkAPI.dispatch(setListFeedback(response.data.data));
    thunkAPI.dispatch(setLoading(false));
    return response.data.data;
  },
);

export const getListEmployees = createAsyncThunk(
  'consultantInterview/getListEmployees',
  async (params: TParams, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await consultantInterviewApi.getListEmployees(params);
    thunkAPI.dispatch(setListEmployees(response.data.data));
    thunkAPI.dispatch(setListEmployeesFilter(response.data.data));
    thunkAPI.dispatch(setLoading(false));
    return response.data.data;
  },
);

export const getListEmployeesFilter = createAsyncThunk(
  'consultantInterview/getListEmployeesFilter',
  async (params: TParams, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await consultantInterviewApi.getListEmployees(params);
    thunkAPI.dispatch(setListEmployeesFilter(response.data.data));
    thunkAPI.dispatch(setLoading(false));
    return response.data.data;
  },
);

export const getScheduleConsultant = createAsyncThunk(
  'consultantInterview/getScheduleConsultant',
  async (params: TScheduleParams, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const paramsRequest: TScheduleParams = {
      ...params,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    };
    const response = await consultantInterviewApi.getScheduleConsultant(paramsRequest);
    thunkAPI.dispatch(setSchedule(response.data.data));
    thunkAPI.dispatch(setLoading(false));
    return response.data.data;
  },
);

export const updateScheduleConsultant = createAsyncThunk(
  'consultantInterview/updateScheduleConsultant',
  async (data: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .updateScheduleConsultant(data.data, data.id)
      .then((response) => {
        thunkAPI.dispatch(setLoading(false));
        if (data.callbackFunction) {
          data.callbackFunction();
        }
        return response.data;
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
  },
);

export const getListReservationStatus = createAsyncThunk(
  'consultantInterview/getListReservationStatus',
  async (params: { consultantId: string; pageNumberValue: number }, thunkAPI) => {
    await consultantInterviewApi
      .getListReservationStatus(params.consultantId, params.pageNumberValue)
      .then((response) => {
        thunkAPI.dispatch(setListReservationStatus(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
      });
  },
);

export const getInfoConsultant = createAsyncThunk(
  'consultant/getInfoConsultant',
  async (consultantId: string, thunkAPI) => {
    await consultantInterviewApi
      .getInfoConsultant(consultantId)
      .then((response) => {
        thunkAPI.dispatch(setInfoConsultant(response.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
      });
  },
);

export const getListCertificate = createAsyncThunk(
  'consultant/getListCertificate',
  async (consultantId: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const response = await consultantInterviewApi.getListCertificate(consultantId);
    thunkAPI.dispatch(setListCertificate(response.data.data));
    thunkAPI.dispatch(setLoading(false));
  },
);

export const createCertificate = createAsyncThunk(
  'consultant/createCertificate',
  async (params: { consultantId: string; data: TCertificate[] }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .createCertificate({
        id: params.consultantId,
        data: params.data,
      })
      .then(() => {});
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateListCertificate = createAsyncThunk(
  'consultant/updateListCertificate',
  async (params: { consultantId: string; data: TCertificate[] }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .updateListCertificate({ consultantId: params.consultantId, data: params.data })
      .then(() => {
        thunkAPI.dispatch(getListCertificate(params.consultantId));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteFileCertificate = createAsyncThunk(
  'consultant/deleteFileCertificate',
  async (params: { filePath: string; consultantId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .deleteFileCertificate(params.filePath)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateStartTime = createAsyncThunk(
  'consultant/updateStartTime',
  async (
    params: {
      interviewId: string;
      consultantId: string;
      eventId: string;
      employeeId: string;
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .updateStartTime({
        interviewId: params.interviewId,
        consultantId: params.consultantId,
        eventId: params.eventId,
        employeeId: params.employeeId,
      })
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({ label: '面談が開始されました', type: ETypeToast.Success }),
        );
        thunkAPI.dispatch(getInterviewDetail(params.interviewId));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateEndTime = createAsyncThunk(
  'consultant/updateEndTime',
  async (interviewId: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .updateEndTime(interviewId)
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({ label: '面談が終了されました', type: ETypeToast.Success }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const activeRowSchedule = createAsyncThunk(
  'consultant/updateEndTime',
  async (data: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .activeRowSchedule(data.consultantId, data)
      .then(() => {})
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

const slice = createSlice({
  name: 'consultantInterview',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setListEmployees: (state, action: PayloadAction<TConsultantInterview>) => {
      state.listEmployees = action.payload.interviews;
      state.totalCancel = action.payload.totalCancel;
      state.totalCompleted = action.payload.totalCompleted;
      state.totalAvailable = action.payload.totalAvailable;
      state.totalScheduled = action.payload.totalScheduled;
      state.totalScheduled = action.payload.totalScheduled;
      state.totalNotFb = action.payload.totalNotFb;
      state.totalSendToEmployee = action.payload.totalSendToEmployee;
    },

    setListEmployeesFilter: (state, action: PayloadAction<TConsultantInterview>) => {
      state.listEmployeesFilter = action.payload.interviews;
      state.totalCancel = action.payload.totalCancel;
      state.totalCompleted = action.payload.totalCompleted;
      state.totalAvailable = action.payload.totalAvailable;
      state.totalScheduled = action.payload.totalScheduled;
      state.totalNotFb = action.payload.totalNotFb;
      state.totalSendToEmployee = action.payload.totalSendToEmployee;
    },

    setSchedule: (state, action: PayloadAction<any>) => {
      state.listSchedule = action.payload;
    },

    setListFeedback: (state, action: PayloadAction<any>) => {
      state.listFeedbacks = action.payload;
    },
    setListReservationStatus: (state, action: PayloadAction<any>) => {
      state.listReservationStatus = action.payload.statistics;
      state.totalAllEvents = action.payload['events-count'];
      state.totalEventsByMonth = action.payload['events-in-month-count'];
    },
    setInfoConsultant: (state, action: PayloadAction<TInterviewerManagement>) => {
      state.infoConsultant = action.payload;
    },
    setListCertificate: (state, action: PayloadAction<TCertificate[]>) => {
      state.listCertificate = action.payload;
      state.loading = false;
    },
  },
});

export const { actions, reducer: ConsultantInterviewReducer } = slice;
export const {
  setLoading,
  setListEmployees,
  setSchedule,
  setListEmployeesFilter,
  setListFeedback,
  setListReservationStatus,
  setInfoConsultant,
  setListCertificate,
} = actions;
