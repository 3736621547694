import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSetListAction } from 'types/types';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  DEFAULT_PER_PAGE_ALL,
  IndexedObject,
  TActionParams,
  TOptionsQuery,
} from 'types/common';
import { setShowToast } from 'store/toast/toastSlice';
import { ETypeToast } from 'app/enum';
import {
  TCalenderFree,
  TEmployeeManagement,
  TImplement,
} from 'types/clientManagement/clientManagement';
import { implementApi } from 'api/implementApi';
import { t } from 'i18next';
import { translations } from '../../locales/translations';
import { convertValueToNumber, getLabelError } from '../../helpers/funcs';
import { consultantInterviewApi } from '../../api/consultantInterview';
import moment from 'moment';

export type ImplementState = {
  loading: boolean;
  listImplement: TImplement[];
  listEmployeeProgress: TImplement[];
  implementDetail: TImplement;
  implementGroupDetail: TImplement[];
  listImplementByEmployee: TImplement[];
  listImplementByConsultant: TImplement[];
  total: number;
  page: number;
  totalPage: number;
  totalEmployeeProgress: number;
  pageEmployeeProgress: number;
  isShowListEvent: boolean;
  facilityNameSelected: string;
  employeeNameSelected: string;
  groupMultiSelected: string[];
  listEmployeeNotJoin: TEmployeeManagement[];
  listCalenderFree: TCalenderFree[];
  listConsultantsFree: any;
  listAllConsultants: any;
  messageErr: string;
  currentLoad: boolean;
  loadingDownload: boolean;
  loadingDownloadCSV: boolean;
};

const initialState: ImplementState = {
  loading: false,
  listImplement: [],
  implementDetail: {},
  implementGroupDetail: [],
  listEmployeeProgress: [],
  listImplementByEmployee: [],
  listImplementByConsultant: [],
  total: 0,
  page: 1,
  totalPage: 1,
  totalEmployeeProgress: 0,
  pageEmployeeProgress: 1,
  isShowListEvent: false,
  facilityNameSelected: '',
  employeeNameSelected: '',
  groupMultiSelected: ['All'],
  listEmployeeNotJoin: [],
  listCalenderFree: [],
  listConsultantsFree: [],
  listAllConsultants: [],
  messageErr: '',
  currentLoad: false,
  loadingDownload: false,
  loadingDownloadCSV: false,
};

export const DEFAULT_ORDER_BY_IMPLEMENT = 'createdAt';
export const DEFAULT_ORDER_DIRECTION_IMPLEMENT = 'desc';
export const getListImplement = createAsyncThunk(
  'implement/getListImplement',
  async (params: TOptionsQuery<TImplement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TImplement> = {
      ...rest,
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_IMPLEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_IMPLEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
    };
    await implementApi
      .getListImplement(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListImplement({
            type: loadMore ? 'loadMore' : 'getList',
            implementS: response.data.data,
            total: response.data._metadata.pagination.total,
            page: response.data._metadata.pagination.page,
            totalPage: response.data._metadata.pagination.totalPage,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });

    thunkAPI.dispatch(setLoading(false));
  },
);

export const registerImplement = createAsyncThunk(
  'implement/registerImplement',
  async (actionParams: TActionParams<TImplement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .registerImplement(actionParams.data)
      .then(() => {
        thunkAPI.dispatch(resetListImplement());
        thunkAPI.dispatch(setPage(1));
        thunkAPI.dispatch(setCurrentLoad(true));
        thunkAPI.dispatch(resetErrMsg());
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        actionParams.funct && actionParams.funct();
      })
      .catch((response) => {
        if (response.response.data.statusCode === 111230) {
          thunkAPI.dispatch(setErrMsg(response.response.data));
          thunkAPI.dispatch(setLoading(false));
        }
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateImplement = createAsyncThunk(
  'implement/updateImplement',
  async (params: { id: string; data: TActionParams<TImplement> }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .updateImplement({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListImplement({
            type: 'edit',
            implement: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(getListImplement({ perPage: 0 }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        params.data.funct && params.data.funct();
      })
      .catch((response) => {
        if (response.response.data.statusCode === 111230) {
          thunkAPI.dispatch(setErrMsg(response.response.data));
          thunkAPI.dispatch(setLoading(false));
        }
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteImplement = createAsyncThunk(
  'implement/deleteImplement',
  async (implement: TImplement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .deleteImplement(String(implement._id))
      .then(() => {
        thunkAPI.dispatch(setListImplement({ type: 'delete', implement: implement }));
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getEmployeeProgress = createAsyncThunk(
  'implement/getEmployeeProgress',
  async (params: TOptionsQuery<TImplement>, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    const { loadMore, ...rest } = params;
    const newParams: TOptionsQuery<TImplement> = {
      ...rest,
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_IMPLEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_IMPLEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE_ALL,
    };
    await implementApi
      .getEmployeeProgress(newParams)
      .then((response) => {
        thunkAPI.dispatch(
          setListEmployeeProgress({
            type: loadMore ? 'loadMore' : 'getList',
            employeeProgressS: response.data.data,
            total: response.data._metadata.pagination.total,
            page: response.data._metadata.pagination.page,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });

    thunkAPI.dispatch(setLoading(false));
  },
);

export const deleteEmployeeProgress = createAsyncThunk(
  'implement/deleteEmployeeProgress',
  async (employeeProgress: TImplement, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .deleteEmployeeProgress(String(employeeProgress?._id), employeeProgress?.eventId ?? '')
      .then(() => {
        thunkAPI.dispatch(
          setListEmployeeProgress({ type: 'delete', employeeProgress: employeeProgress }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.DELETE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateEmployeeProgress = createAsyncThunk(
  'implement/updateEmployeeProgress',
  async (params: { id: string; data: TActionParams<TImplement> }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .updateEmployeeProgress({ id: params.id, data: params.data.data })
      .then((response: any) => {
        thunkAPI.dispatch(
          setListEmployeeProgress({
            type: 'edit',
            employeeProgress: { ...params.data.data, _id: response.data.data._id },
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const cancelInterview = createAsyncThunk(
  'implement/cancelInterview',
  async (params: { id: string; cancelReason: string; isCom: boolean }, thunkAPI) => {
    const { id, cancelReason, isCom } = params;
    thunkAPI.dispatch(setLoading(true));
    await consultantInterviewApi
      .cancelInterview(id, { cancelReason, isCom })
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.CANCEL_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getImplementDetail = createAsyncThunk(
  'implement/getImplementDetail',
  async (id: string) => {
    const response = await implementApi.getImplementDetail(id);
    return response.data.data;
  },
);

export const getImplementGroupDetail = createAsyncThunk(
  'implement/getImplementGroupDetail',
  async (params: any) => {
    const response = await implementApi.getImplementGroupDetail(params);
    const data = response.data.data;
    if (params.selected && !params.selected.includes('All')) {
      return data.filter((obj) => params.selected.includes(obj.facilityId));
    }
    return data;
  },
);

export const getListImplementByEmployee = createAsyncThunk(
  'implement/getListImplementByEmployee',
  async (id: string) => {
    const response = await implementApi.getListEventByEmployee(id);
    return response.data.data;
  },
);

export const getListImplementByEmployeeAndConsultant = createAsyncThunk(
  'implement/getListImplementByEmployeeAndConsultant',
  async (params: any) => {
    const response = await implementApi.getListEventByConsultantAndEmployee(
      params.employeeId,
      params.consultantId,
    );
    return response.data.data;
  },
);

export const getListEmployeeNotJoin = createAsyncThunk(
  'implement/getListEmployeeNotJoin',
  async (id: string) => {
    const response = await implementApi.getListEmployeeNotJoin(id);
    return response.data.data.employees;
  },
);

export const addListEmployeeJoinEvent = createAsyncThunk(
  'implement/addListEmployeeJoinEvent',
  async (data: any[], thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .addListEmployeeJoinEvent(data)
      .then(() => {
        thunkAPI.dispatch(
          getEmployeeProgress({
            eventId: data[0].eventId ?? '',
          }),
        );
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.REGISTER_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const updateConsultant = createAsyncThunk(
  'implement/updateConsultant',
  async (
    params: { implementId: string; employeeProgressId: string; listConsultantId: Array<string> },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .updateConsultant({
        id: params.implementId,
        data: { progressId: params.employeeProgressId, consultantId: params.listConsultantId },
      })
      .then(() => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.UPDATE_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getCalendarInterview = createAsyncThunk(
  'implement/updateConsultant',
  async (params: { employeeProgressId: string; eventId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .getCalendarInterview(params)
      .then((res) => {
        thunkAPI.dispatch(setListCalenderFree(res.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getSpecificCalendarInterview = createAsyncThunk(
  'implement/updateConsultant',
  async (
    params: { eventId: string; employeeProgressId: string; consultantId: string; isCom: boolean },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .getSpecificCalendarInterview(
        params.eventId,
        params.employeeProgressId,
        params.consultantId,
        params.isCom,
      )
      .then((res: any) => {
        thunkAPI.dispatch(setListCalenderFree(res.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getConsultantInterview = createAsyncThunk(
  'implement/updateConsultant',
  async (params: { employeeProgressId: string; dataBody: any }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .getConsultantInterview(params.employeeProgressId, params.dataBody)
      .then((res) => {
        thunkAPI.dispatch(setListConsultantsFree(res.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const getAllConsultantInterview = createAsyncThunk(
  'implement/updateConsultant',
  async (params: { employeeProgressId: string; eventId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .getAllConsultantInterview(params.employeeProgressId, params.eventId)
      .then((res) => {
        thunkAPI.dispatch(setListAllConsultants(res.data.data));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const bookConsultantInterview = createAsyncThunk(
  'implement/updateConsultant',
  async (
    data: {
      eventId: string;
      consultantId: string;
      employeeId: string;
      startTime: string;
      endTime: string;
      isCom: boolean;
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(setLoading(true));
    await implementApi
      .bookConsultantInterview(data)
      .then((res) => {
        thunkAPI.dispatch(
          setShowToast({
            label: t(translations.ID_MASTER.BOOK_INTERVIEW_SUCCESS),
            type: ETypeToast.Success,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      })
      .catch((response) => {
        thunkAPI.dispatch(
          setShowToast({
            label: getLabelError(response.response.data.statusCode),
            type: ETypeToast.Error,
          }),
        );
        thunkAPI.dispatch(setLoading(false));
      });
    thunkAPI.dispatch(setLoading(false));
  },
);

export const downloadFileFeedback = createAsyncThunk(
  'implement/downloadFileFeedback',
  async (params: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingDownload(true));
      await implementApi.downloadFileFeedback(params.eventId).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${params.eventName}_${params.facilityName}_${
          params.clientName
        }_${moment().format('YYYYMMDD')}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      });
      thunkAPI.dispatch(setLoadingDownload(false));
    } catch (e) {
      thunkAPI.dispatch(setLoadingDownload(false));
    }
  },
);

export const resetInterview = createAsyncThunk(
  'implement/resetMeeting',
  async (params: { eventId: string; employeeId: string }, thunkAPI) => {
    await implementApi.resetMeeting(params.eventId, params.employeeId).then((response) => {
      thunkAPI.dispatch(
        getEmployeeProgress({
          eventId: params.eventId,
        }),
      );
    });
    thunkAPI.dispatch(
      setShowToast({
        label: t(translations.ID_MASTER.RESET_MEETING_SUCCESS),
        type: ETypeToast.Success,
      }),
    );
    thunkAPI.dispatch(setLoading(false));
  },
);

export const downloadFileCSV = createAsyncThunk(
  'implement/downloadFileFeedback',
  async (params: any, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingDownloadCSV(true));
      await implementApi.downloadFileCsv(params.eventId).then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${params.eventName}_${params.facilityName}_${
          params.clientName
        }_${moment().format('YYYYMMDD')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      });
      thunkAPI.dispatch(setLoadingDownloadCSV(false));
    } catch (e) {
      thunkAPI.dispatch(setLoadingDownloadCSV(false));
    }
  },
);

export const downloadFileAllFeedback = createAsyncThunk(
  'implement/downloadFileAllFeedback',
  async (params: TOptionsQuery<TImplement>, thunkAPI) => {
    const { loadMore, clientName, ...rest } = params;
    const newParams: TOptionsQuery<TImplement> = {
      ...rest,
      orderBy: rest.orderBy ?? DEFAULT_ORDER_BY_IMPLEMENT,
      orderDirection: rest.orderDirection ?? DEFAULT_ORDER_DIRECTION_IMPLEMENT,
      page: rest.page ?? DEFAULT_PAGE,
      perPage: rest.perPage ?? DEFAULT_PER_PAGE,
    };

    const ranger =
      newParams.ranger?.length === 0
        ? ''
        : '_' + newParams.ranger?.map((date) => date.replace(/\//g, '')).join('_');

    try {
      thunkAPI.dispatch(setLoading(true));
      await implementApi.downloadFileAllFeedback(newParams).then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${clientName}_Feedback${ranger}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      });
      thunkAPI.dispatch(setLoading(false));
    } catch (e) {
      thunkAPI.dispatch(setLoading(false));
    }
  },
);

const slice = createSlice({
  name: 'implement',
  initialState,
  reducers: {
    setListImplement: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        implement?: TImplement;
        implementS?: TImplement[];
        page?: number;
        total?: number;
        totalPage?: number;
      }>,
    ) => {
      const { type, implement, implementS, total, page, totalPage } = action.payload;
      if (implement) {
        switch (type) {
          case 'create':
            state.listImplement = [{ ...implement, isActive: true }, ...state.listImplement];
            break;
          case 'edit':
            state.listImplement = state.listImplement.map((m: TImplement) =>
              m._id === implement._id ? { ...implement, isActive: true } : m,
            );
            break;
          case 'delete':
            state.listImplement = state.listImplement.filter(
              (m: TImplement) => m._id !== implement._id,
            );
            state.total -= 1;
            break;
          case 'stop':
            state.listImplement = state.listImplement.map((m: TImplement) =>
              m._id === implement._id ? { ...implement, isActive: !implement.isActive } : m,
            );
        }
      }
      if (implementS) {
        switch (type) {
          case 'getList':
            state.listImplement = implementS;
            break;
          case 'loadMore':
            state.listImplement = [...state.listImplement, ...implementS];
            break;
        }

        state.page = page ?? DEFAULT_PAGE;
        state.total = convertValueToNumber(total);
        state.totalPage = convertValueToNumber(totalPage);
      }
    },
    setListEmployeeProgress: (
      state,
      action: PayloadAction<{
        type: TSetListAction;
        employeeProgress?: TImplement;
        employeeProgressS?: TImplement[];
        page?: number;
        total?: number;
      }>,
    ) => {
      const { type, employeeProgress, employeeProgressS, total, page } = action.payload;
      if (employeeProgress) {
        switch (type) {
          case 'edit':
            state.listEmployeeProgress = state.listEmployeeProgress.map((m: TImplement) =>
              m._id === employeeProgress._id ? { ...employeeProgress, isActive: true } : m,
            );
            break;
          case 'delete':
            state.listEmployeeProgress = state.listEmployeeProgress.filter(
              (m: TImplement) => m._id !== employeeProgress._id,
            );
            break;
        }
      }
      if (employeeProgressS) {
        switch (type) {
          case 'getList':
            state.listEmployeeProgress = employeeProgressS;
            break;
          case 'loadMore':
            state.listEmployeeProgress = [...state.listEmployeeProgress, ...employeeProgressS];
            break;
        }

        state.pageEmployeeProgress = page ?? DEFAULT_PAGE;
        state.totalEmployeeProgress = total ?? 0;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setGroupMultiSelected: (state, action: PayloadAction<Array<string>>) => {
      state.groupMultiSelected = action.payload;
    },
    setIsShowListEvent: (state, action: PayloadAction<boolean>) => {
      state.isShowListEvent = action.payload;
    },
    setFacilityName: (state, action: PayloadAction<string>) => {
      state.facilityNameSelected = action.payload;
    },
    setEmployeeName: (state, action: PayloadAction<string>) => {
      state.employeeNameSelected = action.payload;
    },
    setListImplementByFacility: (state, action: PayloadAction<Array<TImplement>>) => {
      state.listImplement = action.payload;
    },
    setListCalenderFree: (state, action: PayloadAction<Array<TCalenderFree>>) => {
      state.listCalenderFree = action.payload;
    },
    setListConsultantsFree: (state, action: PayloadAction<Array<TCalenderFree>>) => {
      state.listConsultantsFree = action.payload;
    },
    setListAllConsultants: (state, action: PayloadAction<Array<any>>) => {
      state.listAllConsultants = action.payload;
    },

    setErrMsg: (state, action: PayloadAction<IndexedObject>) => {
      const { message, data } = action.payload ?? {};
      if (message && data) {
        state.messageErr = data.employeeNames?.length
          ? message + '<br/>' + data.employeeNames.join('<br/>')
          : message;
      } else {
        state.messageErr = 'この期間中にイベントが既に存在しています。';
      }
    },
    resetErrMsg: (state) => {
      state.messageErr = '';
    },
    resetListImplement: (state) => {
      state.listImplement = [];
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCurrentLoad: (state, action: PayloadAction<boolean>) => {
      state.currentLoad = action.payload;
    },
    setLoadingDownload: (state, action: PayloadAction<boolean>) => {
      state.loadingDownload = action.payload;
    },
    setLoadingDownloadCSV: (state, action: PayloadAction<boolean>) => {
      state.loadingDownloadCSV = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImplementDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImplementDetail.fulfilled, (state, action: PayloadAction<TImplement>) => {
      state.loading = false;
      state.implementDetail = action.payload;
    });

    builder.addCase(getImplementGroupDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getImplementGroupDetail.fulfilled,
      (state, action: PayloadAction<TImplement[]>) => {
        state.loading = false;
        state.implementGroupDetail = action.payload;
      },
    );

    builder.addCase(getListImplementByEmployee.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getListImplementByEmployee.fulfilled,
      (state, action: PayloadAction<TImplement[]>) => {
        state.loading = false;
        state.listImplementByEmployee = action.payload;
      },
    );
    builder.addCase(getListImplementByEmployeeAndConsultant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getListImplementByEmployeeAndConsultant.fulfilled,
      (state, action: PayloadAction<TImplement[]>) => {
        state.loading = false;
        state.listImplementByConsultant = action.payload;
      },
    );
    builder.addCase(getListEmployeeNotJoin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getListEmployeeNotJoin.fulfilled,
      (state, action: PayloadAction<TEmployeeManagement[]>) => {
        state.loading = false;
        state.listEmployeeNotJoin = action.payload;
      },
    );
    builder.addCase(addListEmployeeJoinEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addListEmployeeJoinEvent.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { actions, reducer: ImplementReducer } = slice;
export const {
  setListImplement,
  setLoading,
  setListEmployeeProgress,
  setIsShowListEvent,
  setFacilityName,
  setEmployeeName,
  setListImplementByFacility,
  setListCalenderFree,
  setListConsultantsFree,
  setListAllConsultants,
  setErrMsg,
  resetErrMsg,
  resetListImplement,
  setPage,
  setCurrentLoad,
  setLoadingDownload,
  setLoadingDownloadCSV,
  setGroupMultiSelected,
} = actions;
export default ImplementReducer;
