import { TImplement, TImplementParam } from 'types/clientManagement/clientManagement';
import queryString from 'querystring';
import { axiosClient } from './axiosClient';
import { TOptionsQuery } from 'types/common';
import { bookConsultantInterview } from '../store/implements/implementsSlice';

export const implementApi = {
  getListImplement: (params: TOptionsQuery<TImplementParam>) => {
    const url = '/event';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  getImplementDetail: (id: string) => {
    const url = `/event/${id}`;
    return axiosClient.get(url);
  },
  getImplementGroupDetail: ({ id, facilityIds }: { id: string; facilityIds: string[] }) => {
    const url = `/event/get-group/${id}`;
    const query = facilityIds
      .map((item) => `facilityIds=${encodeURIComponent(item)}`)
      .join('&');    
    return axiosClient.get(`${url}?${query}`);
  },  
  registerImplement: (data: TImplement) => {
    const url = '/event';
    return axiosClient.post(url, data);
  },
  updateImplement: (params: { id: string; data: TImplement }) => {
    const url = `/event/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  deleteImplement: (id: string) => {
    const url = `/event/${id}`;
    return axiosClient.delete(url);
  },
  getEmployeeProgress: (params: TOptionsQuery<TImplementParam>) => {
    const url = '/employee-progress';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  deleteEmployeeProgress: (id: string, eventId: string) => {
    const url = `/employee-progress/${id}/${eventId}`;
    return axiosClient.delete(url);
  },
  updateEmployeeProgress: (params: { id: string; data: TImplement }) => {
    const url = `/employee-progress/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  getListEventByEmployee: (id: string) => {
    const url = `/employee-progress/getByEmployee/${id}`;
    return axiosClient.get(url);
  },
  getListEventByConsultantAndEmployee: (employeeId: string, consultantId: string) => {
    const url = `/employee-progress/getEventByEmployeeAndConsultant/employeeId=${employeeId}&consultantId=${consultantId}`;
    return axiosClient.get(url);
  },
  addListEmployeeJoinEvent: (data: any[]) => {
    const url = `/employee-progress/multi`;
    return axiosClient.post(url, data);
  },
  getListEmployeeNotJoin: (eventId: string) => {
    const url = `/event/${eventId}/employee-not-join`;
    return axiosClient.get(url);
  },
  updateConsultant: (params: { id: string; data: any }) => {
    const url = `/employee-progress/updateConsultant/${params.id}`;
    return axiosClient.put(url, params.data);
  },
  getCalendarInterview: (params: { employeeProgressId: string; eventId: string }) => {
    const url = `/consultant-management/${params.employeeProgressId}/date?isCom=true&eventId=${params.eventId}`;
    return axiosClient.get(url);
  },
  getSpecificCalendarInterview: (
    eventId: string,
    employeeProgressId: string,
    consultantId: string,
    isCom: boolean,
  ) => {
    const url = `/consultant-management/${employeeProgressId}/get-date/consultant`;
    return axiosClient.post(url, { eventId, consultantId, isCom });
  },
  getConsultantInterview: (employeeId: string, dataBody: any) => {
    const url = `/consultant-management/${employeeId}/get-consultant/date`;
    return axiosClient.post(url, dataBody);
  },
  getAllConsultantInterview: (employeeId: string, eventId: string) => {
    const url = `/consultant-management/${employeeId}/consultant?eventId=${eventId}`;
    return axiosClient.get(url);
  },
  bookConsultantInterview: (dataBody: any) => {
    const url = `/interview/set-calendar`;
    return axiosClient.post(url, dataBody);
  },

  downloadFileFeedback: (id: string) => {
    const url = `/event/download-file-feedback/${id}`;
    return axiosClient.get(url, { responseType: 'blob' });
  },

  resetMeeting: (eventId: string, employeeId: string) => {
    const url = `/interview/reset-interview`;
    return axiosClient.post(url, { eventId, employeeId });
  },

  downloadFileCsv: (id: string) => {
    const url = `/event/download-file-csv/${id}`;
    return axiosClient.get(url, { responseType: 'blob' });
  },

  downloadFileAllFeedback: (params: TOptionsQuery<TImplementParam>) => {
    const url = '/event/download-file-feedback/get-all';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`, { responseType: 'blob' });
  },
};
